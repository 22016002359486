import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import {
  foodTrucksHero,
  foodTrucksSubHero,
  foodTrucksWhiteGlove,
  foodTrucksLargeFeatureA,
  foodTrucksTrusted,
  foodTrucksMultiSection,
  foodTrucksCTA,
  foodTrucksVideo,
  foodTrucksTestimonials,
  foodTrucksCar,
} from "../../data/subverticals/restaurants/foodtrucks";
import { deLovelysDeliciousSlides, heroSuccess } from "../../data/success-stories-data";

import heroBg from "../../images/hero-images/fine-dining-hero.png";

const NoVisualsCustom = loadable(() =>
  import("../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../../components/VideoSection/VideoSection")
);
const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../components/BusinessTypes/BusinessTypes")
);
const TopRestaurantsLogos = loadable(() =>
  import("../../components-v2/TopRestaurantsLogos")
);

const partnersTitle = "Trusted by local restaurants everywhere.";
const whiteGloveImport = "food-trucks-white-glove.png";

const FoodTrucks = () => {
  return (
    <Layout>
      <SEO
        title="Food Truck POS System | Food Truck Point of Sale | SpotOn"
        description="SpotOn food truck point of sale system is made for food truck owners looking for an easy, mobile, sleek solution. Our software works with iOS & Android devices."
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/food-truck-pos"
        productSchemaSR
      />
      <BHero sectionData={foodTrucksHero} heroBg="foodtruck-hero.png" />
      <NoVisualsCustom sectionData={foodTrucksSubHero} simple />
      <WhiteGlove
        sectionData={foodTrucksWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={foodTrucksLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={foodTrucksTrusted} />
      <LargeFeatures
        sectionData={foodTrucksMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={foodTrucksCTA} />
      <SuccessStories sectionData={heroSuccess} slides={deLovelysDeliciousSlides} />
      <VideoSection sectionData={foodTrucksVideo} />
      <TopRestaurantsLogos sectionTitle={partnersTitle} />
      <TestmonialReviews sectionData={foodTrucksTestimonials} />
      <BusinessTypes sectionData={foodTrucksCar} />
    </Layout>
  );
};

export default FoodTrucks;
