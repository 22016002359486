// eslint-disable-next-line import/prefer-default-export
export const foodTrucksHero = {
  title: "Food truck POS system",
  subtext:
    "Get the on-the-go tech your food truck needs to boost efficiency, increase sales, and connect with customers.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/food-truck-pos/demo",
  },
};
export const foodTrucksSubHero = {
  title: "Powerful and flexible solutions that fit your needs",
  subtext:
    "Your food truck is always on the go, which means you have to do more with less. SpotOn is here to help you take all kinds of payments quicker and drive repeat business no matter where you are.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/food-truck-pos/demo",
  },
};

export const foodTrucksWhiteGlove = {
  mainTitle: "Service and support you can always count on",
  title: "In-person installation and training. 24/7 support",
  content:
    "Food trucks aren’t like other restaurants, but you deserve the same white-glove service that they get. Our team will build a custom POS system tailored to your business needs. We'll take care of the installation in-person and continue to be there for you with our 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/food-truck-pos/demo",
  },
};

export const foodTrucksLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "All the tech you need to keep your business rolling",
      blockSubTitle:
        "Food trucks are serious business, but you don’t have the space for a traditional restaurant POS. We’ve got you covered with sleek, portable payment and POS options that are designed with your mobile kitchen in mind. And they come complete with essential business software that allows you to send marketing emails and deals, manage your online reviews, view sales reports, and even launch a digital loyalty program to drive repeat visits.",
      blockImg: "ft_a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/food-truck-pos/demo",
      },
    },
  ],
};

export const foodTrucksTrusted = [
  {
    title: "31%",
    subtext:
      "Increased spending from repeat customers compared to new customers with Loyalty",
  },
  {
    title: "67%",
    subtext: "More visits with online ordering",
  },
  {
    title: "$42",
    subtext:
      "Average return you can expect for every $1 you spend on email marketing",
  },
];

export const foodTrucksMultiSection = {
  sectionTitle:
    "A sleek, portable POS system designed specifically for food trucks",
  featureBlocks: [
    {
      blockTitle: "SpotOn Sidekick: One handheld POS to rule them all",
      blockSubTitle:
        "Get a compact and portable POS device that allows you to take orders in-person and on the go, with fully integrated loyalty and marketing tools that help you improve the customer experience. Never miss a customer, thanks to built-in cellular network capabilities.",
      blockList: [
        "Accept multiple types of payments",
        "Print receipts directly on the device ",
        "Send order tickets to a Bluetooth printer",
        "Combine with online ordering and accept pre-orders",
        "Make data-driven decisions to improve your day-to-day operations",
        "Capture customer emails during checkout",
      ],
      blockImg: "ft_b.png",
      ImgAltTag: "food truck pos",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/payments",
      },
    },
    {
      blockTitle: "Take payments wherever business takes you",
      blockSubTitle:
        "Don’t need a complete POS? Taking payments has never been easier, thanks to our secure mobile payments app and optional Bluetooth card reader.",
      blockList: [],
      blockImg: "ft_c.png",
      ImgAltTag: "food truck pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/food-truck-pos/demo",
      },
    },
    {
      blockTitle: "More orders, fewer lines",
      blockSubTitle:
        "Keep your customers fed and revenue coming in with online ordering for pre-orders. If your food truck changes location constantly, your most loyal customers will be able to order ahead. Orders get sent directly to your POS, creating a seamless experience for everyone. For in-person orders, have customers scan a QR code and order and pay from their phone with orders going immediately to the kitchen.",
      blockList: [
        "Get your own online ordering portal on your website",
        "Own your customer data and retarget guests to improve retention",
        "Promote contactless payments and dining experiences",
      ],
      blockImg: "ft_d.png",
      ImgAltTag: "food truck pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/food-truck-pos/demo",
      },
    },
    {
      blockTitle: "Keep in touch with loyal customers",
      blockSubTitle:
        "Announce new location and events, offer discounts, or simply thank your loyal customers with SpotOn Marketing. Improve your social media and email communication with customizable templates that will make you look like a pro.",
      blockImg: "ft_e.png",
      ImgAltTag: "food truck pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/food-truck-pos/demo",
      },
    },
    {
      blockTitle: "Loyalty rewards for your best customers",
      blockSubTitle:
        "Reward your best customers and allow regulars to become your top supporters. With SpotOn Loyalty, you can create custom rewards that your customers can redeem both in-person and online.",
      blockImg: "ft_f.png",
      ImgAltTag: "food truck pos",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/loyalty",
      },
    },
    {
      blockTitle: "Get discovered more and improve your reputation",
      blockSubTitle:
        "Your reputation is important. With review management software, it's easier than ever to monitor all your reviews in one place and improve your overall ratings from sites like Yelp, TripAdvisor, Google, and Facebook. You can also respond in real-time, addressing good and bad reviews instantly.",
      blockImg: "ft_h.png",
      ImgAltTag: "food truck pos",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/review-management",
      },
    },
    {
      blockTitle: "Attract even more customers",
      blockSubTitle:
        "When potential customers look for food in their area, where do they go? Straight to the search bar. Win them over instantly with a website designed by experts and optimized to show up higher in Google search results.",
      blockImg: "ft-f.png",
      ImgAltTag: "food truck pos",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/e-commerce",
      },
    },
  ],
};

export const foodTrucksCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/food-truck-pos/demo",
  },
};

export const foodTrucksVideo = {
  title: "See SpotOn Sidekick in action",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  posterWrapperClass: "relative",
  videoUrlId: "CKYuJeylAWM",
};

export const foodTrucksTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const foodTrucksArticles = {
  title: "Expert advice on growing your business",
  bgText: "Articles.",
};

export const foodTrucksFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const foodTrucksCar = {
  title: "Run a different type of restaurant",
  subtext: "Food trucks are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "fine-dining.png",
      cardTitle: "Fine Dining",
      cardContent: "Tailor-made restaurant solutions",
      icon: "fine-dining.png",
      linkTo: "/fine-dining-pos",
    },
    {
      bg: "quick-dining.png",
      cardTitle: "Quick service restaurant",
      cardContent: "Work faster and smarter",
      icon: "qsr.png",
      linkTo: "/quick-service-pos",
    },
    {
      bg: "casual-dining.png",
      cardTitle: "Casual dining",
      cardContent: "Unlock your restaurant’s potential",
      icon: "casual-dining.png",
      linkTo: "/casual-dining-pos",
    },
  ],
};
